@import url("https://fonts.googleapis.com/css?family=DM+Sans:400,500,700&display=swap");
* {
  box-sizing: border-box;
}


input[type=radio] {
  display: none;
}

.card {
  position: absolute;
  width: 32%;
  height: 100%;
  left: 0;
  right: 0;
  margin: auto;
  transition: transform 0.4s ease;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .container {
  width: 100%;
  max-width: 800px;
  max-height: 600px;
  height: 100%;
  transform-style: preserve-3d;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: aquamarine;
} */

.cards {
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%,0);
  width: 60%;
  height: 50vh;
  margin-bottom: 20px;
}

.container img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  -o-object-fit: cover;
     object-fit: cover;
}

#item-1:checked ~ .cards #song-3,
#item-2:checked ~ .cards #song-1,
#item-3:checked ~ .cards #song-2{
  transform: translatex(-40%) scale(0.8);
  opacity: 0.4;
  z-index: 0;
}

#item-1:checked ~ .cards #song-2,
#item-2:checked ~ .cards #song-3,
#item-3:checked ~ .cards #song-1{
  transform: translatex(40%) scale(0.8);
  opacity: 0.4;
  z-index: 0;
}

#item-1:checked ~ .cards #song-1,
#item-2:checked ~ .cards #song-2,
#item-3:checked ~ .cards #song-3 {
  transform: translatex(0) scale(1);
  opacity: 1;
  z-index: 1;
}

.card h2{
    position: absolute;
    color: white;
}


@media (max-width: 1000px) {
  .card {
    position: absolute;
    width: 42%;
  }
}