.carousel{
    height: 100vh;
    width: 95%;
    position: relative;
    color: white;
    /* position: absolute; */
    /* background-color: bisque; */
    /* top: 50%; */
    /* left: 50%; */
    /* transform: translate(-50%,-50%); */

}

.carousel h3{
    font-size: 2vmax;
    position: absolute;
    left: 53%;
    top: 0%;
    transform: translate(-50%,0%);
}

.carousel_cards{
    margin-top: -1vmax;
    height: 80vh;
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 20%;
    left: 0%;
    transform: translate(0%,-0%);
    /* align-items: center; */
    /* overflow: auto; */

    /* padding: 1vmax; */
    /* background-color: #fff; */
}

.carousel_card{
    height: 100%;
    width: 10vmax;
    /* background-color: #fff; */
    /* padding: 1vmax; */
    position: relative;
   
}

.carousel_card2{
    height: 90vh;
    width: 20vmax;
    /* background-color: cadetblue; */
    position: relative;
    border-radius: 1vmax;
    margin-left: 1vmax;
    display: flex; 
    justify-content: center;
    align-items: center;
    overflow: hidden;

    /* margin-top: -1vmax; */
}

.carousel_card video{
    height: 45vh;
    width: 96%;
    /* margin-left: 2vmax; */
    border-radius: 0.3vmax;
    position: absolute;
    left: 50%;
    top: 5%;
    transform: translate(-50%,0%);
}

.prev{
    left: 2%;

}

.next{
    left: 97%;
}



.btn{
padding: 0.5vmax;
height: 10vmax;
width: 3vmax;
position: absolute;
top: 30%;
font-size: 2vmax;
opacity: 0.7;
/* border: 1px solid transparent; */
border-radius: 1vmax;
outline: none;
z-index: 999;
background-color: rgb(112, 112, 112);
color: white;
}

.carousel .btn:nth-child(1){
    /* background-color: #fff; */
    left: 0%;
}

.btn:hover{
    opacity: 0.8;
    color: rgb(38, 225, 246);
    background-color: rgba(109, 109, 109, 0.517);
    border: 2px solid rgb(38, 225, 246);
}

.carousal_buttons{
    width: 95%;
    height: 30%;
    /* background-color: aquamarine; */
    background-color: #ffffff;
    border: 2px solid  #e67079;
    font-size: 1.3vmax;
    color: #e67079;
}

.carousel_card2_text h4{
    margin-bottom: -1vmax;
}

.carousel_card2_text h6{
    color: red;
}

.carousel_card2 p{
    margin-top: -1vmax;
    font-size: 1vmax;
}

.carousel_card2_text{
    height: 25%;
    width: 90%;
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%,0%);
    /* background-color: bisque; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


@media (max-width: 500px) {
    
.carousel h3{
    font-size: 5vmax;
    left: 2%;
    transform: translate(-0%,-0%);
    text-align: center;
}

.carousel_cards{
    top: 25%;
}

.carousel_card2{
    width: 48vmax;
}

.carousel_card2_text h4{
    font-size: 4vmax;
}

.carousel_card2_text p{
    font-size: 2vmax;
}

}

@media (max-width: 900px) {
    .carousel h3 {
        font-size: 2vmax;
        position: absolute;
        left: 53%;
        top: 5%;
        transform: translate(-50%,0%);
    }
    
    .catalogue_three{
    height: 81vh;
    }
    
    .carousel h3 {
        font-size: 2vmax;
        position: absolute;
        left: 53%;
        top: 5%;
        transform: translate(-50%,0%);
    }
    
    .catalogue_three_subcard{
    height: 71vh;
    width: 90%;
    }
    
    .carousel{
    height: 73vh;
    width: 95%;
    }
    
    .carousel h3{
    top: 5%;
    }
    
    .carousel_card img {
        
    height: 29vh;
    width: 90%;
    
    }
    
    .carousel_card2 {
    height: 64vh;
    }
}