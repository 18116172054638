@import url('https://fonts.googleapis.com/css?family=Montserrat:400,600&display=swap');

.circle_card_container{
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    height: 55vh;
    width: 80%;
    /* position: relative; */
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: #fff; */
}

.circle_card_container>.card{
    position: relative;
    height: 20vmax;
    width: 30%;
    background-color:#20598e;  
    text-align:center;
    margin-top:50px;
    /* background:#27496D;     */
    border:10px solid transparent;
    color:#fff;
    Font-family: Montserrat;
    padding:50px;
    border-radius:50%;
    margin: -1vmax;
    border: 2px solid #fff;
    box-sizing: border-box;
   
  }
  
  @keyframes circling-border {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

.circling-border {
    position: relative;
    height: 21vmax;
    width: 32%;
    animation: circling-border 1s infinite ease-in-out;
  }

.circle_card_container>.card:nth-child(2){
    background:#0e3155; 
}

.circle_card_container>.card h5{
    font-size: 1.5vmax;
}

.circle_card_container>.card p{
    /* color: pink; */
    margin-bottom: 2vmax;
}

.circle_card_container>.card .circle_card_btn{
    padding: 0.5vmax 1vmax;
    margin-top: 1vmax;
    border: 1px solid white;
}

.circle_card_container .card:hover .circle_card_btn{
   background-color:  rgb(121, 239, 255);
   color: black;
   
}


@media (max-width: 1000px) {
    .circle_card_container>.card {
        position: relative;
        height: 26vmax;
        width: 29%;
    }
    
    .circle_card_container {
        height: 55vh;
        width: 97%;
    }
}


@media (max-width: 500px) {
    .circle_card_container>.card .circle_card_btn {
        padding: 0.5vmax 1vmax;
        margin-top: 1vmax;
        font-size: 1.3vmax;
        border: 1px solid white;
    }

    .circle_card_container>.card h5 {
        font-size: 3vmax;
        margin-bottom: 0vmax;
    }
    
    .circle_card_container>.card p {
        /* color: pink; */
        margin-bottom: 2vmax;
        font-size: 1.5vmax;
    }

    .circle_card_container>.card {
        position: relative;
        height: 33vmax;
        width: 78%;
    }
    
    .circle_card_container {
        flex-direction: column;
    }
}
