.segments{
    position: relative;
    margin-left: 5%;
    height: 65vh;
    width: 90%;
    background-color: #131922;
    border-radius: 1vmax;
}

.segments>a>img{
    height: 100%;
    width: 100%;
    border-radius: 1vmax;
}

#segment_mobile{
   display: none;
}

.segment_cards{
    padding: 1vmax;
    border-radius: 0.5vmax;
    position: absolute;
    top: 15%;
    left: 5%;
    width: 90%;
    min-height:52vh;
    background-color:#08c0c8;
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.segment_cards>img{
    height: 50vh;
    width: 100%;
}


.segments #segmentTitle{
    position: absolute;
    left: 50%;
    top: -4%;
    transform: translate(-50%,0%);
    color: white;
    font-size: 2.8vmax;
    font-family: Acratica-Demo;
}

.segments a{
    width: 33%;
}

.segment_card{
    position: relative;
    /* background-color: rgb(124, 112, 150); */
    height:42vh;
    width: 100%;
    text-align: center;

}

.segment_card img{
    height:23vh;
    top: 2%;
    left:50%;
    transform:translate(-50%,0%);
    position: absolute;
    
}

.segment_card h1{
    position: absolute;
    top: 70%;
    left:50%;
    transform:translate(-50%,0%);
    font-size: 3vmax;
    color: white;
}

.segment_card_text{
    width: 90%;
    position: absolute;
    top: 50%;
    left: 5%;
}

.segment_card p{
    color: white;
    font-size: 1vmax;
}

#card_btn{
    position: absolute;
    left: 25%;
    background-color: #344256;
    color: white;
    font-size: 1vmax;
}

#card_btn:hover{
    cursor: pointer;
    background-color: #181f29;

}

@media (max-width: 500px){

    .segments{
        height: 118vh;
    }

    #segment_mobile{
        display: initial;
    }

    #segment_desktop{
        display: none;
    }

    .segments #segmentTitle {
        top: 0%;
        left: 50%;
        font-size: 2.9vmax;
    }
    .segment_card{
        width: 100%;
        height: 40vh;
        margin-top: 3vmax;
    }

    .segment_card:nth-child(1){
        margin-bottom: 3vmax;
    }

    .segment_card img{
        top: 0%;
        left: 50%;
    }
        

    .segment_card h1{
        font-size:4vmax;
        left: 50%;
        top: 70%;
        transform: translate(-50%, 0%);
    }

    .segment_card_text{
        top: 54%;
        
    }

    .segments a{
        width: 100%;
    }

    .segment_cards{
        flex-direction: column;
        top: 6%;
    }

    .segment_card p{
        font-size: 1.8vmax;
        
    }
        




    
      
      
      
      
      
      
      
     

}
