.catalogue_one{
    height: 100vh;
    width: 100%;
    background-color: #181f29;
    overflow: hidden;
}

.catalogue_one h1{
    position: absolute;
    left: 50%;
    top: 45%;
    transform: translate(-50%,0%);
    font-size: 5.4vmax;
    width: 70%;
    font-family: vogue;
    z-index: 999;
    color: rgb(121, 239, 255);
}



.catalogue_one h1:hover{
    animation: y-axis-animation 2s ease-in-out 0s infinite alternate;

}

.catalogue_card{
    height: 47vh;
    width: 18%;
    border-radius: 2vmax;
    background-color: rgba(240, 248, 255, 0);
    position: absolute;
    transform: rotateY(40deg) rotateX(30deg);
    overflow: hidden;
    opacity: 0.7;
    display: flex;
    justify-content: center;
    align-items: center;
    /* box-shadow: rgba(255, 255, 255, 0.1) 0px 8px 24px, rgba(255, 255, 255, 0.1) 0px 16px 56px, rgba(255, 255, 255, 0.1) 0px 24px 80px; */
    /* box-shadow: rgba(255, 255, 255, 0.07) 0px 1px 2px, rgba(255, 255, 255, 0.07) 0px 2px 4px, rgba(255, 255, 255, 0.07) 0px 4px 8px, rgba(255, 255, 255, 0.07) 0px 8px 16px, rgba(255, 255, 255, 0.07) 0px 16px 32px, rgba(255, 255, 255, 0.07) 0px 32px 64px; */
}

#tilt_card_one{
    left: 12%;
    top: 51%;
    z-index: 2;

}

#tilt_card_two{
    height: 14vh;
    width: 16%;
    left: 23%;
    top: 16%;
    transform: rotateY(55deg) rotateX(15deg) rotateZ(10deg);
   

}

#tilt_card_three{
    height: 15vh;
    width: 13%;
    left: 61%;
    top: 17%;
    border-radius: 0.5vmax;
    transform: rotateY(45deg) rotateX(-40deg) rotateZ(-10deg);
}

#tilt_card_four{
    height: 65vh;
    width: 18%;
    left: 42%;
    top: 18%;
    border-radius: 2vmax;
    transform: rotateY(15deg) rotateX(50deg) rotateZ(10deg) ;
}

#tilt_card_five{
    height: 54vh;
    width: 24%;
    left: 71%;
    top: 46%;
    border-radius: 2vmax;
    transform: rotateY(-40deg) rotateX(10deg) rotateZ(20deg);
}

.catalogue_card video{
    /* height: 100%; */
    width: 100%;
}

@keyframes y-axis-animation {
    from {transform: translate(-50%,0%) rotateY(0deg)}
    to {
        transform: translate(-50%,0%) rotateY(-60deg);
        opacity: 0;
    }
}


/*ARRROW ANIMATION*/
/* 
$ani-speed: 2s;
$ani-delay: 1s; */

.arrow_down_animation{
    position: absolute;
    top: 90%;
    height: 15vh;
    left: 50%;
    transform: translate(-50%,0%);
}

.arrow {
    opacity: 0;
    position: absolute;
    left: 50%;
    top: 0%;
    transform-origin: 50% 50%;
    transform: translate3d(-50%, -50%, 0);
}

.arrow-first {
    animation: arrow-movement 3s ease-in-out infinite;
}
.arrow-second {
    animation: arrow-movement 3s 2s ease-in-out infinite;
}

.arrow:before,
.arrow:after {
    /* color: rgb(121, 239, 255); */
    background:  rgb(121, 239, 255);
    content: "";
    display: block;
    height: 3px;
    position: absolute;
    top: 0;
    left: 0;
    width: 30px;
}

.arrow:before {
    transform: rotate(45deg) translateX(-23%);
    transform-origin: top left;
}

.arrow:after {
    transform: rotate(-45deg) translateX(23%);
    transform-origin: top right;
}


@keyframes arrow-movement {
    0% {
        opacity: 0;
        top: 45%;
    }
    70% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}



/* CATALOGUE SECTION TWO CSS */

.catalogue_two{
    height: 150vh;
    width: 100%;
    position: relative;
    overflow: hidden;
    color: white;

}

.catalogue_two p{
    color: white;
    position: absolute;
    top: 5%;
    left: 50%;
    transform: translate(-50%,0%);
}


.catalogue_two h2{
    font-weight: 400;
    position: absolute;
    top: 1%;
    left: 50%;
    padding: 0.5vmax;
    padding-left: 1vmax;
    padding-right: 1vmax;
    transform: translate(-50%,-50%);
    border: 1px solid rgb(121, 239, 255);
    
}

.catalogue_two .catalogue_videos{
    height: 120vh;
    width: 75%;
    /* background-color: #fff; */
    position: absolute;
    top: 12%;
    left: 50%;
    transform: translate(-50%,0%);
    /* overflow: hidden; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* position: relative; */
}

.catalogue_videos video{
    max-height: 100%;
    max-width: 100%;
}

.catalogue_two .vid_card_top{
    padding: 1vmax;
    height: 60%;
    width: 85%;
    /* background-color: beige; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    
}

.vid_card_top_left {
    height: 96%;
    width: 40%;
    /* border: 1px solid blue; */
    /* background-color: aqua; */
    border-radius: 1vmax;
    overflow: hidden;
    display: flex;
    justify-content: flex-end;
}

.vid_card_top_left video{
    height: 146%;
    border-radius: 1vmax;
}

.vid_card_top_right{
    height: 96%;
    width: 58%;
    /* background-color: rgb(255, 255, 255); */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;

}

.vid_card_top_right video{
    height: 150%;
    border-radius: 1vmax;
}

.vid_card_top_right_top{
    height: 47%;
    width: 100%;
    /* background-color: #181f29; */
    border-radius: 1vmax;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

.vid_card_top_right_btm{
    height: 47%;
    width: 100%;
    /* background-color: #181f29; */
    border-radius: 1vmax;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
}

.catalogue_two .vid_card_btm{
    padding: 1vmax;
    padding-top: 0vmax;
    height: 40%;
    width: 110%;
    /* background-color: rgb(137, 40, 194); */
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.catalogue_two .vid_card_btm video{
    border-radius: 1vmax;
}

.vid_card_btm_card{
    height: 100%;
    width: 32%;
    /* background-color: #7d5656; */
    border-radius: 1vmax;
    overflow: hidden;
}


/*CATALOGUE THIRD SECTION CSS*/

.catalogue_three{
    height: 110vh;
    width: 100%;
    background-color: #fff;
    position: relative;
    overflow: hidden;
}

.catalogue_three_subcard{
    height: 100vh;
    width: 90%;
    /* background-color: #fff; */
    position: absolute;
    top: 5%;
    left: 50%;
    transform: translate(-50%,0%);
    border-radius: 2vmax;
    background-color: #181f29;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

@media (max-width: 650px) {
    #tilt_card_two {
        height: 16vh;
        width: 23%;
        left: 12%;
        top: 18%;
        transform: rotateY(55deg) rotateX(15deg) rotateZ(10deg);
    }
    
    
    #tilt_card_three {
        height: 20vh;
        width: 23%;
        left: 65%;
        top: 15%;
        border-radius: 0.5vmax;
        transform: rotateY(45deg) rotateX(-40deg) rotateZ(-10deg);
    }
    
    #tilt_card_four {
        height: 47vh;
        width: 51%;
        left: 27%;
        top: 26%;
        border-radius: 2vmax;
        transform: rotateY(15deg) rotateX(50deg) rotateZ(10deg);
    }
    
    
    #tilt_card_one {
        left: 7%;
        top: 60%;
        z-index: 2;
    }
    
    .catalogue_card {
        height: 25vh;
        width: 29%;
    }
    
    #tilt_card_five {
        height: 24vh;
        width: 27%;
        left: 67%;
        top: 65%;
        border-radius: 2vmax;
        transform: rotateY(-40deg) rotateX(10deg) rotateZ(20deg);
    }




    .catalogue_two {
        height: 190vh !important;
        width: 100%;
    }
    
    .catalogue_two p {
        left: 0%;
        transform: translate(0%,0%);
    }
    
    .catalogue_two .catalogue_videos {
        min-height: 219vh;
        width: 75%;
        top: 0%;
        flex-direction: column;
    }
    
    .catalogue_two .vid_card_top {
        height: 180vh;
        width: 100%;
        flex-direction: column;
        align-items: stretch;
    }
    
    .vid_card_top_left {
        height: 55vh;
        width: 100%;
        justify-content: center;
    }
    
    .vid_card_top_right {
        height: 85vh;
        width: 100%;
        flex-direction: column;
    }
    
    .vid_card_top_right_top {
        width: 100%;
    }
    
    .vid_card_top_right_btm {
        width: 100%;
    }
    
    .catalogue_two .vid_card_btm {
        margin-top: 2vmax;
        min-height: 68vh;
        width: 100%;
        flex-direction: column;
    }
    
    .vid_card_btm_card {
        height: 20vh;
        width: 100%;
    }


    .catalogue_three {
        height: 91vh;
    }
    
    .catalogue_three_subcard {
        height: 83vh;
    }
    
    .carousel h3 {
        top: 4%;
    }
    
    .catalogue_two .vid_card_top {
        padding: 1vmax;
        height: 47%;
        width: 94%;
    }
    
    .catalogue_two .vid_card_btm {
        padding: 1vmax;
        padding-top: 0vmax;
        height: 32%;
    }

}

@media (max-width: 900px) {

    #tilt_card_two {
        height: 16vh;
        width: 23%;
        left: 12%;
        top: 18%;
        transform: rotateY(55deg) rotateX(15deg) rotateZ(10deg);
    }
    
    
    #tilt_card_three {
        height: 20vh;
        width: 23%;
        left: 65%;
        top: 15%;
        border-radius: 0.5vmax;
        transform: rotateY(45deg) rotateX(-40deg) rotateZ(-10deg);
    }
    
    #tilt_card_four {
        height: 51vh;
        width: 34%;
        left: 27%;
        top: 26%;
        border-radius: 2vmax;
        transform: rotateY(15deg) rotateX(50deg) rotateZ(10deg);
    }
    
    
    #tilt_card_one {
        left: 7%;
        top: 60%;
        z-index: 2;
    }
    
    .catalogue_card {
        height: 25vh;
        width: 29%;
    }
    
    #tilt_card_five {
        height: 24vh;
        width: 27%;
        left: 67%;
        top: 65%;
        border-radius: 2vmax;
        transform: rotateY(-40deg) rotateX(10deg) rotateZ(20deg);
    }




    .catalogue_two {
        height: 81vh;
    }
    
    .catalogue_two .catalogue_videos {
        height: 99vh;
        width: 75%;
    }
    
    .catalogue_two .vid_card_top {
        padding: 1vmax;
        height: 39%;
        width: 94%;
    }



    

}