.nav{
    height: 6vmax;
    width: 100%;
    background-color: #181f29;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: product sans;
    
}

.links a{
    opacity: 0.7;
}

a{
    text-decoration: none;
    color: white;
    
    letter-spacing: 0.1vmax;
    font-weight: 100;
    /* background-color: #fff; */
}

.is-active{
    color: rgb(121, 239, 255);
}

.links{
    height: inherit;
    width: 8vmax;
    /* background-color: blue; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.links2:hover{
    color: rgb(121, 239, 255);
}


a:hover{
    color: rgb(121, 239, 255);

}
.links .line{
    height: 60%;
    width: 0.05vmax;
    background-color: black;
    opacity: 0.1;
}

.links:hover a{
    color: rgb(121, 239, 255);
}


#left{
    position: relative;
    height: inherit;
    width: 60%;
    /* background-color: red; */
    display: flex;
    font-family: product sans;
   
}

#logo{
    position: absolute;
    z-index: 9999;
    height: 100%;
    width: 20%;
    /* background-color: #fff; */
    display: flex;
    justify-content: center;
    align-items: center;
}

#logo img{
    height: 3.5vmax;
    margin-top: 1vmax;
    margin-left: 3vmax;
}

#allLinks {
margin-right: 3vmax;
    height: inherit;
    width: 40%;
   display: flex;
   justify-content: space-between;
   align-items: center;
}





#right{
    height: inherit;
    width: 20%;
    /* background-color: blue; */
    display: flex;
   justify-content: right;
   align-items: center;
}

#right a{
    margin-right: 2vmax;
}

#allLinks .dropdown {
    display: initial;
  }
  
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  li {
    padding: 8px 12px;
  }
  
  li:hover {
    background-color: rgba(0, 0, 0, 0.14);
    cursor: pointer;
  }

.allLinks_mobile img{
    height: 6vh;
    position: absolute;
    left: 5%;
    top: 1%;
}

#menubtn button{
    background-color: transparent;
    border: none;
    display: none;
}

#menu_img{
    position: absolute;
    top: 50%;
    left: -150%;
    height: 3vmax;
}


.allLinks_mobile{
    display: none;
    position: absolute;
    /* left: 100%; */
    height:65vh;
    top: 0%;
    width: 100%;
    background-color: rgb(0, 0, 0);
    z-index: 99999;
}

#allLinks_mob{
    position: absolute;
    top: 10%;
    width: 100%;
    padding: 1vmax;
    padding-left: 3.5vmax;
}

.links_mob{
    display: flex;
    align-items: center;
    border-radius: 0.5vmax;
    margin-top: 1.2vmax;
    font-size: 3vmax;
    padding: 1vmax;
    /* padding-left: 5vmax; */
    height: 7vh;
    width: 85%;
    border-bottom: 0.18vmax solid grey;
    /* background-color: rgb(236, 236, 236); */
    color:#181f29;
    font-family: product sans;
}

.dot{
    height: 0.7vmax;
    width: 0.7vmax;
    background-color: #fff;
    border-radius: 50%;
}

.link_mob{
    color:#ffffff;
    margin-left: 2vmax;
}

@media (max-width : 500px) {

    .nav{
        padding-left: 3vmax;
    }
    .links{
        display: none;
    }
    #allLinks .dropdown {
        display: initial;
      }

      #menu_img {
        position: absolute;
        top: 50%;
        left: -184%;}
      

    #menubtn button {
        opacity: 1;
        cursor: pointer;
        position: absolute;
        right: 1%;
        top: 0.5%;
        display: initial;
    }
}