.freeConsultation{
    position: relative;
    height: 100vh;
    width: 100%;
    background-color: #181f29;
    color: white;
}

.submitted{
    position: relative;
    height: 100vh;
    width: 100%;
    background-color: #181f29;
    color: white;
}


.consultationBox{
    border-radius: 1vmax;
    padding: 1vmax;
    position: absolute;
    top: 15%;
    left: 50%;
    transform: translate(-50%,0%);
    height: 80vh;
    width: 85%;
    background-color: #131922;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.consultationBox h1{
    position: absolute;
    left: 33%;
    font-family: ogg;
}

.consultationBox p{
    position: absolute;
    top: 13%;
    left: 29%;
    letter-spacing: 0.05vmax;
}

.consultationBox form{
    padding: 1vmax;
    position: absolute;
    top: 26%;
    left: 5%;
    height: 70%;
    width: 90%;
    /* background-color: rgb(137, 36, 36); */
    display: flex;
}

.consultationBox form input{
    font-size: 1.3vmax;
    color: white;
    border: 0;
    outline: 0;
    background: transparent;
    border-bottom: 1px solid #e5e5e5;
}

.personalInfo{
    width: 50%;
    /* background-color: aqua */
}

.extraInfo{
    width: 50%;
    /* background-color: rgb(128, 50, 157) */
}

.personalInfo input{
    width: 85%;
    height: 3vmax;
    margin: 1vmax;
}

.extraInfo input{
    width: 85%;
    height: 3vmax;
    margin: 1vmax;
    
}

.consultationBox form button{
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translate(-50%,0%);
}

@media (max-width:500px){

    .consultationBox{
        top: 10%;
    }

    .consultationBox form{
        flex-direction: column;
    }

    .consultationBox h1{
        top: 5%;
        left: 3%;
        font-size: 3vmax;
    }

    .consultationBox p{
        left: 5%;
        top: 20%;
        width: 90%;
        font-size: 1.5vmax;
    }

    .consultationBox form{
        top: 32%;
       
    }

    .personalInfo{
        width: 100%;
    }

    .personalInfo input{
        width: 95%;
        
    }

    .extraInfo{
        width: 100%;
    }

    .extraInfo input{
        width: 95%;
    }

    .consultationBox form button{
        bottom: 15%;
        width: 50%;
        left: 25%;
        font-size: 1.8vmax;
    }
}