.strip{
    position: relative;
    height: 45vh;
    width: 100%;
    /* background-color: #78a4e1; */
    color: white;
    overflow: hidden;
}

.strip video{
    position: absolute;
    top: 0%;
    left: 0%;
    z-index: 1;
    width: 100%;
}

.strip_card{
    position: absolute;
    top: 10%;
    z-index: 3;
    /* right: 10%; */
    display: flex;
    justify-content: center;
    padding: 2vmax;
    font-size: 1.7vmax;
    /* height: 45vh; */
    width: 100%;
    /* background-color: #12161d; */
    color: white;
}

.strip_btn{
    bottom: -10%;
    position: absolute;
    background-color: transparent;
    color: white;
    border: 1px solid white;
    /* width: 25vmax; */
}

.strip_btn:hover{
    cursor: pointer;
    background-color:#08c0c8;
    color: black;
    border: 1px solid black;
}

.strip_left{
    position: absolute;
    left: 5%;
    height: 60vh;
    width: 50%;
    /* background-color: #fff; */
}

.strip_left h2{
    letter-spacing: 0.12vmax;
    color: white;
    font-size: 2vmax;
}

.strip_left p{
    width: 70%;
}

.strip_left input{
    position: absolute;
    top: 50%;
    height: 3vmax;
    width: 80%;
    border: 0;
    outline: 0;
    background: transparent;
    border-bottom: 1px solid #e5e5e5;
    font-size: 2vmax;
    color: white;
}

.strip_left form button{
    position: absolute;
    top: 50%;
    right: 5%;
}

#strip_icons{
    top: 20%;
    right: 0%;
    position: absolute;
    z-index: 99999;
    /* background-color: #fff; */
}

.strip_icon{
    opacity: 0.6;
    margin-bottom: 2vmax;
}

.strip_btn{
    right: 45%;
}


.footer{
    position: relative;
    height: 50vh;
    width: 100%;
    background-color: #131922;
    color: white;
    font-family: product sans;
}

#footer_logo{
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translate(-50%,0%);
    height: 10vmax;
}

.footer_details{
    height: 18vh;
    width: 60%;
    position: absolute;
    top: 70%;
    left: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: translate(-50%,-50%);
}

.footer_details p{
    line-height: 0.8vmax;
    font-size: 1.2vmax;
    letter-spacing: 0.08vmax;
    /* text-align: left; */
}

.footer_contact{}

#footer_icons{
    display: flex;
    justify-content: space-between;
    height: 5vh;
    width: 15%;
    /* background-color: rgb(128, 23, 75); */
}

.icon:nth-child(2){
    padding-top: 0.5vmax;
    /* background-color: antiquewhite; */
}

.whatsapp_float {
    /* position: fixed;
    width: 2vmax;
    height: 2vmax;
    bottom: 40px;
    right: 40px; */
    /* background-color: #25d366; */
    color: #FFF;
    font-size: 1.9vmax;
    /* border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: 2px 2px 3px #999;
    z-index: 100; */
}

.whatsapp-icon {
    margin-top: 0.5vmax;
}

@media(max-width:500px){

    .strip{
        height: 26vh;
    }

   
    
    .strip_card h2{
        margin-top: 6vmax;
        font-size: 2.3vmax;
    }

   .strip .strip_btn{
       
        left: 22%;
        right: 25%;
    }

    .footer{
        height: 30vh;
    }

    .footer_details{
        height:12vh;
        top: 75%;
    }

    #footer_icons{
        width: 50%;
    }

    .iconImg{
        height: 4.5vmax;
        /* background-color: rgb(124, 51, 114); */
    }

    .icon:nth-child(2) .iconImg{
        /* background-color: bisque; */
        height: 2.9vmax;
        margin-top: 0.3vmax;
    }
    .whatsapp-icon {
        margin-top: 10px;
    }

    .whatsapp_float {
        /* width: 40px;
        height: 40px;
        bottom: 20px;
        right: 10px; */
        font-size: 2vmax;
    }
}