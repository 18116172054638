.AboutUs{
    position: relative;
    height: 120vh;
    width: 100%;
    background-color: #181f29;
    color: white;
}

.aboutSeg2{
    height: 95vh;
    width: 100%;
    background-color: #181f29;
    padding: 0.5vmax;
    display: initial;
}

.aboutSeg2 #About_Mobile{
    display: none;
}

.aboutSeg2 h1{
    position: absolute;
    left: 43%;
    /* top: 0%; */
    font-size: 3vmax;
    font-family: ogg;
}

.AboutSeg1{
    position: relative;
    height: 200vh;
    width: 100%;
    background-color: #181f29;
  
} 



.AboutSeg1 h1{
    position: absolute;
    left: 43%;
    /* top: 0%; */
    font-size: 3vmax;
    font-family: ogg;
}

.AboutSeg1 h4{
    position: absolute;
    left: 50%;
    top: 5%;
    transform: translate(-50%,0%);
    letter-spacing: 0.13vmax;
    /* font-size: 3vmax; */
    font-family: ogg;
}

.allAboutSeg{
    padding: 2vmax;
    position: absolute;
    top: 7%;
}

.eachSegment{
    position: relative;
    display: flex;
    margin-top: 1vmax;
    /* background-color: antiquewhite; */
    min-height: 25vmax;
    width: 100%;
    justify-content: space-between;
}

.aboutSegText{
    min-height: 20vmax;
    width: 50%;
    /* background-color: aquamarine; */
    margin-right: 10vmax;
    margin-top: 5vmax;
    
}

.aboutSegText h2{
    font-size: 2.3vmax;
    font-family: ogg;
}

.aboutSegText p{
   margin-top: 2vmax;
}

.segImage{
    margin-top: 2vmax;
    margin-left: 15vmax;
    height: 55vh;
    width: 20%;
    border-radius: 0.5vmax;
}


.segEven img{
    position: absolute;
    right: 10%;
    top: 50%;
    transform: translate(0%,-55%);
}

.segEven .aboutSegText{
    position: absolute;
    left: 17%;
    top: 50%;
    margin-top: 0;
    transform: translate(0%,-50%);
}

.AboutSeg1 span{
    font-size: 1.5vmax;
}

@media(max-width:500px){

    .AboutSeg1{
      
        height: 100vh;
    }

    .allAboutSeg{
        top: 7%;
    }

    .segEven{
        height: 10vh;
    }

    .segEven img{
        position: relative;
        margin-left: 12vmax;
        margin-top: 20vmax;
    }
    
    .segEven .aboutSegText{
        position: relative;
        left: 0%;
    }

.allAboutSeg{
    top: 10%;
}
    
    
.AboutSeg1 h1{
    top: 1%;
    font-size: 3.5vmax;
   left: 36%;
}
.AboutSeg1 h4{
    left: 50%;
    top: 8%;
    font-size: 1.2vmax;
    transform: translate(-50%,0%);
}

.aboutSegText{
    margin-top: 2vmax;
    width: 100%;
}

.aboutSegText h2{
   margin-left: 0vmax;
}

.aboutSegText p{
    min-height: 9vmax;
    margin-left: 2vmax;
    text-align: justify;
}



.eachSegment{
    position: relative;
    flex-direction: column;
    margin-top: 3vmax;
}

.segImage{
    height: 40vmax;
    width: 63%;
    margin-left: 8vmax;
    margin-top: -19vmax;
    display: none;
}

.aboutSeg2 #About_desktop{
    display: none;
}

.aboutSeg2 #About_Mobile{
    display: initial;
    height: 100vh;
    width: 95%;
    /* display: none; */
}

.AboutUs{
    height: 137vh;
}

}