.pageOne{
    height: 100vh;
    width: 100%;
    background-color: #181f29;
    
}

.home{
    background-color: #181f29;
}

.home>a{
    font-size: 5vmax;
    position: fixed;
    z-index: 999;
    color: #25D366;
    top: 75vh;
    right: 1%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background-color: #673c3c; */
}

.home>a>p{
    font-size: 1.2vmax;
    font-weight: 500;
    color:#08c0c8 !important;
    z-index: 999;
}

.pageOne_MainBox{
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-40%);
    height: 90vh;
    width: 100%;
    background-color: #181f29;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.pageOne_MainBox video{
    position: absolute;
    z-index: -1;
    /* width: 100%; */
}

.mainBox_centerText{
    position: relative;
    margin-top: -6vmax;
    height: 60vh;
    width: 80%;
    /* background-color: rgb(144, 5, 5); */
    /* position:absolute; */

}

.mainBox_centerText a{
    opacity: 1;
}

.mainBox_centerText #mainBox_img1{
    opacity: 0.15;
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    height: 30vmax;
}

.mainBox_centerText .mainBox_star{
    opacity: 0.3;
    position: absolute;

}

.mainBox_centerText h1{
    text-align: center;
    margin-right: 2vmax;
    font-size: 3vmax;
    color: white;
    font-family: ogg;
}

.mainBox_centerText p span{
    font-size: 5vmax;
}

.mainBox_centerText p{
    position: absolute;
    font-size: 5vmax;
    text-align: center;
    color: white;
    top: 4%;
    left: 50%;
    transform: translate(-50%,0);
    letter-spacing: 0.2vmax;
    font-family: montserrat;
    /* background-color: bisque; */
    width: 100%;
}

.mainBox_centerText button{
    position: absolute;
    left: 50%;
    top: 52%;
    transform: translate(-50%,-50%);
}

button{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3vmax;
    padding: 1vmax;
    font-size: 1.2vmax;
    border-radius: 1.3vmax;
    background-color:#08c0c8;
    opacity: 1;
}

button:hover{
    opacity: 1;
    cursor: pointer;
    color: white;
}


.mainBox_serviceList{
    position: absolute;
   bottom: 7%;
    left: 50%;
    transform: translate(-50%,0);
    text-align: center;
    color: white;
    display: flex;
    width: 85%;
    /* background-color: aquamarine; */
    justify-content: center;
   
}



.mainBox_serviceList .mainBox_service{
    padding-right: 1vmax;
    margin-left: 1vmax;
    border-right: 1px solid rgba(255, 255, 255, 0.513);
}
.mainBox_service:nth-child(7){
    /* color: red; */
    border: none;

}

.segment{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70vh;
    width: 100%;
    /* background-color: #181f29; */
    color: white;
}

button{
    outline: none;
}

@media (max-width:500px){

    .home>a{
        top: 89vh;
    right: 3%;
    }

    .pageOne{
        height: 67vh;
    }

    .mainBox_serviceList p{
        font-size: 1.3vmax;
    }

    .mainBox_centerText p{
        top: 28%;
        left: 45%;
        font-size: 3vmax;
    }

    .mainBox_centerText p span{
        font-size: 3vmax;
    }

    .mainBox_centerText h1{
        font-size: 2.5vmax;
    }

    .mainBox_serviceList{
        left: 50%;
        flex-wrap: wrap;
        width: 100%;
        justify-content: center;
    }

    .mainBox_centerText button{
        left: 45%;
        top: 75%;
        transform: translate(-50%,0%);
        opacity: 1;
    }

    .pageOne_MainBox{
        height: 56vh;
        top: 35%;
    }
    .mainBox_centerText{
        top: -8%;
        margin-top: 0vmax;
        left: 5%;
        height: 30vh;
        width: 60%;
    }

    #logo img {
        height: 4.4vmax;
        margin-top: 2vmax;
        margin-left: 12vmax;
    }
   
}

