@import url(https://fonts.googleapis.com/css?family=Poiret+One);
/********************  Loading Page ************************/
.loadFont {
  font-family: "Poiret One", cursive;
}

.loading{
    height: 100vh;
    width: 100%;
    background-color: #181f29;
    color: rgb(121, 239, 255);
}

.loading h3{
    font-size: 1.5vmax;
}

/* Progress Bar Placement & Styling */
#progressive {
  position: absolute;
  width: 300px;
  height: 100px;
  z-index: 15;
  top: 50%;
  left: 50%;
  margin: -100px 0 0 -150px;
  
}


.progress {
   
  position: relative;
  height: 10px;
  display: block;
  width: 100%;
  background-color: #f5f5f5;
  border-radius: 20px;
  margin: 0.5rem 0 1rem 0;
  overflow: hidden;
  -webkit-box-shadow:0px 0px 59px 15px rgba(121,239,255,0.9);
-moz-box-shadow: 0px 0px 59px 15px rgba(121,239,255,0.9);
box-shadow: 0px 0px 59px 15px rgba(121,239,255,0.9);
}

.progress-bar {
  float: left;
  height: 100%;
  background-color: rgb(121, 239, 255);
  -webkit-transition: width 5s ease;
  -o-transition: width 5s ease;
  transition: width 5s ease;
}

#side1 {
  animation-delay: 0.2s;
  -moz-animation-delay: 0.2s;
  -webkit-animation-delay: 0.2s;
}
#side2 {
  animation-delay: 0.3s;
  -moz-animation-delay: 0.3s;
  -webkit-animation-delay: 0.3s;
}
#side3 {
  animation-delay: 0.4s;
  -moz-animation-delay: 0.4s;
  -webkit-animation-delay: 0.4s;
}
