.team{
    position: relative;
    height: 150vh;
    width: 100%;
    background-color: #181f29;
    
}

.team>h1{
    position: absolute;
    top: 0%;
    left: 50%;
    transform: translate(-50%,0%);
    font-family: ogg;
    font-size: 3vmax;
    color: white;
}

.team_cards{
    border-radius: 1vmax;
    position: absolute;
    top: 15%;
    left:7%;
    min-height: 100vh;
    padding: 1vmax;
    width: 85%;
    background-color: #131922;
}

.team_card{
    border-radius: 0.3vmax;
    background-size: cover;
    min-height: 50vh;
    margin-top: -0.3vmax;
    width: 28%;
    margin-left: 0vmax;
    background-color: #fff;
    margin-top: 1vmax;
    margin-right: 2vmax;
}

.team_card:nth-child(1){
    /* background-color: aqua; */
    background-image: url(../ASSETS/founder/co-founder.jpeg);
}

.team_card:nth-child(2){
    background-position: center;
    background-image: url(../ASSETS/founder/founder.jpg);
}

.team_card_true{
    position: relative;
    margin-left: 2vmax;
    width: 95%;
    color: #fff;
    /* background-color: bisque; */
    display: flex;
    text-align: center;
}

.team_text{
    /* position: absolute; */
    margin-top: 3vmax;
    text-align: left;
    margin-right: 2vmax;
    top: 10%;
    height: 30vh;
    width: 40%;
    /* background-color: aquamarine; */
}

.team_text p{
    font-style: italic;
}

.team_card_true:nth-child(2){
    justify-content: right;
    /* background-color: rgb(167, 167, 167); */
}

.team_card_true:nth-child(2) .team_text{
    /* background-color: antiquewhite; */
    text-align: right;
}

.team_card_true:nth-child(2) .team_text p{
    text-align: left;
}

.team_text .team_name{
    font-size: 2vmax;
    font-weight: 100;
 }


@media (max-width:500px){

    .team>h1{
        top: 1%;
    }

    .team_card{
        width: 100%;
        }

        .team_text .team_name{
           font-size: 3.4vmax;
        }
        
        .team_card_true{
        flex-direction: column;
        }
        
        .team_text{
        width: 100%;
        }
        
        .team_cards{
        top: 8%;
        }
        
        .team{
        height: 195vh;
        }

        .team_card_true:nth-child(2){
            height: 80vh;
            position: relative;
            /* background-color: #fff; */
        }
        .team_card_true:nth-child(2) .team_text {
        text-align: left;
        position: absolute;
        top: 62%;
        /* background-color: aquamarine; */
        }
      
}